<template>
  <AdminComp title="Admin Portal DoCSoc" collection="hardware" />
</template>

<script>
import AdminComp from "@/components/adminComp";
export default {
  components: { AdminComp }
};
</script>
